var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" 360 Clash ")])]), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Create account to join tournaments. ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Join and compete in local or global events! ")]), _c('validation-observer', {
    ref: "registerForm"
  }, [_c('b-form', {
    staticClass: "auth-register-form mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.register($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nickname",
      "label-for": "nickname"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nickname",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "nickname",
            "state": errors.length > 0 ? false : null,
            "name": "register-nickname",
            "placeholder": "johnDoe"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.left",
            value: 'Please use one uppercase letter, one lowercase letter, and one number.',
            expression: "\n                  'Please use one uppercase letter, one lowercase letter, and one number.'\n                ",
            modifiers: {
              "hover": true,
              "left": true
            }
          }],
          staticClass: "float-right my-1 mr-1",
          attrs: {
            "icon": "HelpCircleIcon",
            "size": "16"
          }
        })];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "register-email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.regEmail,
            callback: function callback($$v) {
              _vm.regEmail = $$v;
            },
            expression: "regEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.left",
            value: 'Please use one uppercase letter, one lowercase letter, and one number.',
            expression: "\n                  'Please use one uppercase letter, one lowercase letter, and one number.'\n                ",
            modifiers: {
              "hover": true,
              "left": true
            }
          }],
          staticClass: "float-right my-1 mr-1",
          attrs: {
            "icon": "HelpCircleIcon",
            "size": "16"
          }
        })];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Password",
      "label-for": "password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": {
        required: true,
        regex: _vm.passwordRegex
      },
      "name": "Password",
      "vid": "password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "register-password",
            "placeholder": "⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Repeat Password",
      "label-for": "repeatPassword"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Repeat Password",
      "rules": "required|confirmed:password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "repeatPassword",
            "state": errors.length > 0 ? false : null,
            "type": _vm.repeatPasswordFieldType,
            "name": "register-repeat-password",
            "placeholder": "⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
          },
          model: {
            value: _vm.repeatPassword,
            callback: function callback($$v) {
              _vm.repeatPassword = $$v;
            },
            expression: "repeatPassword"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.repeatPasswordToggleIcon
          },
          on: {
            "click": _vm.toggleRepeatPasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Privacy policy",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-checkbox', {
          attrs: {
            "id": "register-privacy-policy",
            "name": "checkbox-1"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        }, [_vm._v(" I agree to "), _c('b-link', {
          attrs: {
            "href": "https://about.360clash.com/terms-and-conditions/",
            "target": "_blank"
          }
        }, [_vm._v(" privacy policy & terms ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('spinning-button', {
    attrs: {
      "is-loading": _vm.isLoading,
      "block": "block",
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Sign up ")])], 1)], 1), _c('dismissible-message-box', {
    attrs: {
      "show": _vm.showErrorNotifications,
      "variant": _vm.MessageType.ERROR
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.registrationErrors[0]) + " ")], 1), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("Already have an account? ")]), _c('b-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('span', [_vm._v("Sign in instead")])])], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }