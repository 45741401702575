<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            360 Clash
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Create account to join tournaments.
        </b-card-title>
        <b-card-text class="mb-2">
          Join and compete in local or global events!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register"
          >
            <!-- nickname -->
            <b-form-group
              label="Nickname"
              label-for="nickname"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nickname"
                rules="required"
              >
                <b-form-input
                  id="nickname"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  name="register-nickname"
                  placeholder="johnDoe"
                />
                <small class="text-danger">{{ errors[0] }}</small>

                <!-- tooltip -->
                <feather-icon
                  v-b-tooltip.hover.left="
                    'Please use one uppercase letter, one lowercase letter, and one number.'
                  "
                  class="float-right my-1 mr-1"
                  icon="HelpCircleIcon"
                  size="16"
                />
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>

                <!-- tooltip -->
                <feather-icon
                  v-b-tooltip.hover.left="
                    'Please use one uppercase letter, one lowercase letter, and one number.'
                  "
                  class="float-right my-1 mr-1"
                  icon="HelpCircleIcon"
                  size="16"
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  regex: passwordRegex
                }"
                name="Password"
                vid="password"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- repeat password -->
            <b-form-group
              label="Repeat Password"
              label-for="repeatPassword"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Repeat Password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="repeatPassword"
                    v-model="repeatPassword"
                    :state="errors.length > 0 ? false : null"
                    :type="repeatPasswordFieldType"
                    class="form-control-merge"
                    name="register-repeat-password"
                    placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="repeatPasswordToggleIcon"
                      class="cursor-pointer"
                      @click="toggleRepeatPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="Privacy policy"
                rules="required"
              >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link
                    href="https://about.360clash.com/terms-and-conditions/"
                    target="_blank"
                  >
                    privacy policy & terms
                  </b-link>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <spinning-button
              :is-loading="isLoading"
              block="block"
              type="submit"
              variant="primary"
            >
              Sign up
            </spinning-button>
          </b-form>
        </validation-observer>

        <dismissible-message-box
          :show="showErrorNotifications"
          :variant="MessageType.ERROR"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ registrationErrors[0] }}
        </dismissible-message-box>

        <b-card-text class="text-center mt-2">
          <span>Already have an account?&nbsp;</span>
          <b-link :to="{ name: 'login' }">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import {
  confirmed, email, regex, required,
} from '@validations'
import {
  togglePasswordVisibility,
  toggleRepeatPasswordVisibility,
} from '@core/mixins/ui/forms'
import { heightFade } from '@core/directives/animations'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  components: {
    SpinningButton,
    DismissibleMessageBox,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, toggleRepeatPasswordVisibility],
  data() {
    return {
      name: '',
      regEmail: '',
      password: '',
      repeatPassword: '',
      status: '',

      // validation rules
      required,
      email,
      regex,
      confirmed,
      //  1 uppercase letter, 1 lowercase letter, 1 number, and one special character
      passwordRegex: new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
      ),

      // error box
      registrationErrors: [],
      showErrorNotifications: false,
      showSuccessNotification: false,

      // submit button
      isLoading: false,
    }
  },
  computed: {
    MessageType() {
      return MessageType
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    repeatPasswordToggleIcon() {
      return this.repeatPasswordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    async register() {
      this.registrationErrors = []
      this.showErrorNotifications = false

      const formValid = await this.$refs.registerForm.validate()

      // eslint-disable-next-line no-empty
      if (!formValid) {
        return
      }

      this.isLoading = true
      const { success } = this.$api.player.register(
        this.name,
        this.regEmail,
        this.password,
        this.repeatPassword,
      )

      this.isLoading = false

      if (success === false) {
        this.registrationErrors.push(
          'There was a problem registering you. Please try again or contact our support.',
        )

        this.showErrorNotifications = true

        return
      }

      await this.$router.push({ name: 'auth-register-success' })
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/pages/page-auth.scss';
</style>
